/* skills animations */

@keyframes magnify {
    0%,100%{
      height: 10vw;
      margin: 0.5rem;
    }
    50%{
      height: 9vw;
      margin: 0.7rem;
    }
  }
  @keyframes magnify-smaller {
    0%,100%{
      height: 15vw;
      margin: 0.2rem;
    }
    50%{
      height: 15.1vw;
      margin: 0.25rem;
    }
  }

/* skills */
  .skills-heading{
    position: relative;
    left: 15vh;
    top: 10rem;
    margin-top: 2rem;
    font-size: 50px;
  }

  .skill-img{
    position: relative;
    height: 9rem;
    margin: 0.5rem;
    box-shadow: #fff 10px;
    animation: magnify 1s infinite;
  }

  .skills{
    position: relative;
    width: 70vw;
    left: 15vw;
    top: 15rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  #mongodb-img{
    height: 8vh;
    width: 20vw;
  }

@media screen and (max-width: 1385px) {
    .skills-heading {
        font-size: 50px;
        left: 20vw;
      }
    .skills{
        font-size: 18px;
        width: 70vw;
        left: 20vw;
    }
    .skills{
        left: 10vw;
        width: 84vw;
    }

    .skill-img {
        height: 20px;
        margin: 2px;
        left: 0px;
        animation: magnify-smaller 1s infinite;
    }
}
